<template lang="pug">
  form(@submit="onSubmit").password-renew
    div
      .label-block
        label {{'authentication.password.old'|translate}}
      .input-block
        input(:placeholder='"authentication.password.old.placeholder"|translate', type='password', name='oldPassword', v-model="oldPassword")
    div
      .label-block
        label {{'authentication.password.new'|translate}}
        span.info
          i.fp4.fp4-circle-info(:class="{error:!isValid()}",v-if="newPassword")
          .hover-errors
            .title {{'authentication.password.rules'|translate}}
            .hover-error-content
              .rule(v-if="passwordPolicies.nb_char")
                span.state
                  i.fp4(:class="newPassword.length>=passwordPolicies.nb_char|check")
                span.text {{textRule('length',passwordPolicies.nb_char)}}
              .rule(v-if="passwordPolicies.nb_int")
                span.state
                  i.fp4(:class="nbInt>=passwordPolicies.nb_int|check")
                span.text {{textRule('integer',passwordPolicies.nb_int)}}
              .rule(v-if="passwordPolicies.nb_cchar")
                span.state
                  i.fp4(:class="nbCaps>=passwordPolicies.nb_cchar|check")
                span.text {{textRule('cap',passwordPolicies.nb_cchar)}}
              .rule(v-if="passwordPolicies.nb_schar")
                span.state
                  i.fp4(:class="nbSpecial>=passwordPolicies.nb_schar|check")
                span.text {{textRule('special',passwordPolicies.nb_schar)}}
      .input-block( :class="{error:newPassword&&!isValid()}")
        input(@input="onPasswordChange",:placeholder='"authentication.password.new.placeholder"|translate', type='password', name='password', v-model="newPassword")
        .score
          .score-internal(:class="scoreClass")
          .text {{'authentication.password.score.'+score|translate}}

    div
      .label-block
        label {{'authentication.password.confirm'|translate}}
      .input-block(:class="{error:confirmPassword && newPassword !== confirmPassword}")
        input(:placeholder='"authentication.password.confirm.placeholder"|translate', type='password', name='confirmPassword', v-model="confirmPassword")
        .error-message(v-if="confirmPassword && newPassword !== confirmPassword") {{ 'authentication.password.confirm.error' |translate}}

    .actions
      button.login-button.btn-login.btn.btn-primary(type='submit',:style="buttonStyle",:disabled="!isReady()") {{'authentication.submit'|translate}}
</template>

<script>
import zxcvbn from 'zxcvbn'
export default {
  filters: {
    check: function (status) {
      if (status) return 'fp4-check-bold'
      return 'fp4-xmark-bold'
    }
  },
  data () {
    return {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      score: 0
    }
  },
  computed: {
    scoreClass () {
      return 'score-internal-' + this.score
    },
    nbInt () {
      let number = 0
      for (const i in this.newPassword) {
        if (!isNaN(this.newPassword.charAt(i))) number++
      }
      return number
    },
    nbSpecial () {
      let number = 0
      const specialChars = '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~'
      for (const i in this.newPassword) {
        if (specialChars.indexOf(this.newPassword.charAt(i)) !== -1) number++
      }
      return number
    },
    nbCaps () {
      let number = 0
      for (const i in this.newPassword) {
        if (/[A-Z]/.test(this.newPassword.charAt(i))) number++
      }
      return number
    },
    passwordPolicies () {
      return {
        nb_char: parseInt(this.$store.getters.PREFERENCES.password_policies.nb_char),
        nb_int: parseInt(this.$store.getters.PREFERENCES.password_policies.nb_int),
        nb_schar: parseInt(this.$store.getters.PREFERENCES.password_policies.nb_schar),
        nb_cchar: parseInt(this.$store.getters.PREFERENCES.password_policies.nb_cchar)
      }
    },
    buttonStyle () {
      return {
        color: 'white', // TODO - Add a detect for light or dark background
        backgroundColor: this.$store.getters.PREFERENCES.color || '#617de9'
      }
    }
  },
  methods: {
    textRule (text, number) {
      if (number > 1) text += 's'
      return this.$t('authentication.password.rules.' + text, [number])
    },
    onPasswordChange () {
      this.score = zxcvbn(this.newPassword).score
    },
    async onSubmit ($event) {
      $event.preventDefault()
      const { oldPassword, newPassword } = this
      try {
        await this.$store.dispatch('PASSWORD_RENEW', { oldPassword, newPassword })
      } catch (err) {
        this.$fpuiMessageBlock.error(err)
      }
    },
    isReady () {
      if (!this.oldPassword) return false
      if (!this.newPassword) return false
      if (!this.confirmPassword) return false
      if (this.newPassword !== this.confirmPassword) return false
      if (!this.isValid) return false
      return true
    },
    isValid () {
      if (!this.newPassword) return false
      if (this.newPassword.length < this.passwordPolicies.nb_char) return false
      if (this.nbInt < this.passwordPolicies.nb_int) return false
      if (this.nbCaps < this.passwordPolicies.nb_cchar) return false
      if (this.nbSpecial < this.passwordPolicies.nb_schar) return false
      return true
    }
  }
}
</script>
<style lang="less">
.password-renew {
  .input-block {
    margin:10px 0 30px;
    position: relative;
    input {
      margin: 0;
    }
    &.error input {
      border-color: #464344;
    }
    .error-message {
      color:#ff6f90;;
    }
  }

  .score {
    position: absolute;
    right:7px;
    top:17px;
    background-color: rgba(151,167,183,.3);
    height:3px;
    width: 80px;
    border-radius: 2px;
    .score-internal {
      height:3px;
    }
    .score-internal-0 {
      background:#facd30;
      width:20%;
    }
    .score-internal-1 {
      background:#facd30;
      width:40%;
    }
    .score-internal-2 {
      background:#facd30;
      width:60%;
    }
    .score-internal-3 {
      background:#9edf10;
      width:80%;
    }
    .score-internal-4 {
      background:#9edf10;
      width:100%;
    }
    .text {
      color:#97a7b7;
      font-size:11px;
      position: absolute;
      bottom:2px;
      right:0;
    }
  }

  .label-block > label, .label-block > span {
    vertical-align: top;
    margin-right: 10px;
    display: inline-block;
  }
  .inline-block {
    display: inline-block;
  }
  .fp4-circle-info {
    color: #9edf10;
    font-size:16px;
    &.error {
      color: #ff6f90;
    }
  }
  .hover-errors {
    position: absolute;
    background: #fff;
    box-shadow: 0 12px 35px 0 rgba(62,69,80,.55);
    padding: 21px 16px;
    border-radius: 10px;
    display:none;
    z-index: 2;
    font-size:13px;
    .title {
      font-size: 15px;
      text-align:center;
      font-weight: 700;
      &:after {
        content: "";
        display: block;
        width: 70px;
        margin: auto;
        margin-top: 17px;
        margin-bottom: 17px;
        height: 2px;
        background-color: #00ccf9;
      }
    }
    i{
      margin-right:15px;
    }
    .fp4-check-bold {
      color: #9edf10;
    }
    .fp4-xmark-bold {
      color: #ff6f90;
    }
  }
  .info {
    > i {cursor:pointer}
  }
  .info:hover {
    .hover-errors {
      display: block;
    }
  }
}
</style>
